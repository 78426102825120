<template>
  <v-container fluid>
    <v-card class="pa-5 mt-3">
      <v-data-table
        :headers="headers"
        :items="doctors"
        :search="search"
        :items-per-page="5"
        :loading="loading"
        :loading-text="$t('loading')"
        class=""
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" persistent max-width="600px">
              <template v-slot:activator="{ on, attrs }" v-if="isInRole('7')">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t("add") }}
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </template>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card>
                  <v-toolbar color="primary darken-1" dark>
                    <v-card-title>
                      <span class="">{{
                        (editedIndex === -1 ? $t("add") : $t("edit")) +
                          " " +
                          $t("doctors.doctor")
                      }}</span>
                    </v-card-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-row style="position: relative" no-gutters>
                            <v-col>
                              <v-card
                                class="pa-1"
                                style="
                                  position: absolute;
                                  top: -20px;
                                  z-index: 1;
                                "
                                min-width="110"
                                min-height="110"
                              >
                                <v-img
                                  max-width="100"
                                  contain
                                  max-height="110"
                                  :src="url"
                                ></v-img>
                              </v-card>
                            </v-col>
                            <v-col>
                              <v-file-input
                                :rules="[
                                  (value) =>
                                    !value ||
                                    value.size < 2000000 ||
                                    $t('error.FileIsTooBig'),
                                ]"
                                prepend-icon="mdi-camera"
                                accept="image/png, image/jpeg, image/bmp"
                                @change="Preview_image"
                                v-model="image"
                                :label="$t('doctors.doctorPicture')"
                              >
                              </v-file-input>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.doctorFirstName"
                            :rules="rules"
                            :label="$t('doctors.doctorFirstName')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.doctorMiddleName"
                            :label="$t('doctors.doctorMiddleName')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.doctorLastName"
                            :label="$t('doctors.doctorLastName')"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.doctorPhone"
                            :label="$t('doctors.doctorPhone')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.doctorEmail"
                            :label="$t('doctors.doctorEmail')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.doctorDegree"
                            :label="$t('doctors.doctorDegree')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.doctorSpecialization"
                            :label="$t('doctors.doctorSpecialization')"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.doctorNote"
                            :label="$t('doctors.doctorNote')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-checkbox
                            v-model="editedItem.isActive"
                            :label="$t('doctors.isActive')"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="close">
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      class="white--text"
                      @click="save"
                      :disabled="!valid"
                    >
                      {{ $t("save") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-dialog>

            <confirm-dialog
              :openDialog="dialogDelete"
              :onClicked="deleteItemConfirm"
              :onClose="closeDelete"
            ></confirm-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="text-end">
            <v-tooltip top v-if="isInRole('8')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="editItem(item)" v-bind="attrs" v-on="on">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("edit") }}</span>
            </v-tooltip>
            <v-tooltip top v-if="isInRole('9')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red darken-2"
                  icon
                  @click="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("delete") }}</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:[`item.isActive`]="{ item }">
          <v-checkbox disabled v-model="item.isActive"></v-checkbox>
        </template>

        <!-- <template v-slot:[`item.doctorPicture`]="{ item }">
          <v-img max-width="50" contain :src="getImageUrl(item.guid)"></v-img>
        </template> -->
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";

export default {
  components: { ConfirmDialog },
  data() {
    return {
      valid: true,
      loading: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      search: "",
      headers: [
        // {
        //   text: this.$t("doctors.doctorPicture"),
        //   value: "doctorPicture",
        // },
        {
          text: this.$t("doctors.doctorCode"),
          value: "doctorCode",
        },
        {
          text: this.$t("doctors.doctorName"),
          value: "doctorName",
        },
        {
          text: this.$t("doctors.doctorPhone"),
          value: "doctorPhone",
        },
        {
          text: this.$t("doctors.doctorEmail"),
          value: "doctorEmail",
        },
        {
          text: this.$t("doctors.doctorDegree"),
          value: "doctorDegree",
        },
        {
          text: this.$t("doctors.doctorSpecialization"),
          value: "doctorSpecialization",
        },
        {
          text: this.$t("doctors.doctorNote"),
          value: "doctorNote",
        },
        {
          text: this.$t("doctors.isActive"),
          value: "isActive",
        },
        { text: "", value: "actions" },
      ],
      doctors: [],
      editedItem: {
        doctorFirstName: "",
        doctorMiddleName: "",
        doctorLastName: "",
        doctorPhone: null,
        doctorEmail: null,
        doctorDegree: "",
        doctorSpecialization: "",
        doctorNote: "",
        isActive: true,
      },
      defaultItem: {
        doctorFirstName: "",
        doctorMiddleName: "",
        doctorLastName: "",
        doctorPhone: null,
        doctorEmail: null,
        doctorDegree: "",
        doctorSpecialization: "",
        doctorNote: "",
        isActive: true,
      },
      groups: [
        { text: "General", value: 0 },
        { text: "Dental", value: 1 },
        { text: "Operation", value: 2 },
      ],
      rules: [(value) => !!value || this.$t("ThisFieldIsRequired")],
      url: require("@/assets/Doctor.png"),
      image: null,
    };
  },
  created() {
    this.loading = true;
    this.refreshTable();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Service" : "Edit Service";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    image(val) {
      if (!val || val == null) {
        this.url = require("@/assets/Doctor.png");
      }
    },
  },

  methods: {
    getText(i) {
      var result = this.groups.filter((obj) => {
        return obj.value == i;
      });
      return result[0].text;
    },
    editItem(item) {
      this.editedIndex = this.doctors.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.url = this.editedItem.pictureUrl
        ? this.editedItem.pictureUrl
        : require("@/assets/Doctor.png");
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.doctors.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.doctors[this.editedIndex];

      axios
        .delete("Doctor/Delete?guid=" + deleteItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.url = require("@/assets/Doctor.png");
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    Preview_image() {
      if (this.image != null && this.image != "")
        this.url = URL.createObjectURL(this.image);
    },

    refreshTable() {
      axios
        .get("Doctor")
        .then((response) => {
          this.doctors = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        var data = new FormData();
        if (this.image != null) {
          data.append("file", this.image);
        }

        for (var key in this.editedItem) {
          if (this.editedItem[key]) data.append(key, this.editedItem[key]);
        }

        if (this.editedIndex > -1) {
          var updatedItem = this.doctors[this.editedIndex];
          data.append("guid", updatedItem.guid);

          axios
            .post("Doctor/Update", data)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            });
          Object.assign(this.doctors[this.editedIndex], this.editedItem);
        } else {
          axios
            .post("Doctor/Add", data)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            });
        }
      }
    },
  },
};
</script>

<style></style>
